import React, {useState, FunctionComponent, lazy} from 'react';
import {Route, Redirect, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import formFlow from '../influx/ingot/formFlowB.json';
import {isAuthenticated} from '../store/authStore';

// Recipt Signature Flow Paths
const AppFormData = formFlow;
const pathsURLS = AppFormData.form.map((section: any) => section.pages.map((page: any) => page.path));
const pathsFlattened = pathsURLS.flat();

const DigitizedReceipt = lazy(() => import('../pages/DigitizedReceipt'));
const VideoCall = lazy(() => import('../pages/Customer/Screens/CameraCapture'));
const Auth = lazy(() => import('../pages/Auth'));
const Overview = lazy(() => import('../pages/Overview'));
const Audit = lazy(() => import('../pages/Audit'));
const TellerPortal = lazy(() => import('../pages/TellerPortal'));
const Receipts = lazy(() => import('../pages/Receipts'));
const Reports = lazy(() => import('../pages/Reports'));
const Locations = lazy(() => import('../pages/Locations'));
const LocationId = lazy(() => import('../pages/Locations/SelectedLocation'));
const Settings = lazy(() => import('../pages/Settings'));
const FormFlow = lazy(() => import('../components/FormFlow'));
const Error404 = lazy(() => import('../pages/Error/Error404'));
const Components = lazy(() => import('../pages/Authorisation'));
const Users = lazy(() => import('../pages/Users'));
const Customer = lazy(() => import('../pages/Customer'));
const Setup = lazy(() => import('../pages/Customer/Setup'));
const Preload = lazy(() => import('../pages/TellerPortal/Preload'));
const Success = lazy(() => import('../pages/TellerPortal/Success'));
const Failure = lazy(() => import('../pages/TellerPortal/Failure'));
const SuccessUpload = lazy(() => import('../pages/TellerPortal/SuccessUpload'));
const DevicePairIMEI = lazy(() => import('../pages/DevicePair/Screens/PairIMEI'));
const DevicePair = lazy(() => import('../pages/DevicePair/Screens/PairDetails')); // not needed
const DeviceSuccess = lazy(() => import('../pages/DevicePair/Screens/PairInitiated'));
const TabletPair = lazy(() => import('../pages/DevicePair')); // not needed
const TabletPairIMEI = lazy(() => import('../pages/DevicePair/Screens/TabletIMEI'));
const TabletPairSuccess = lazy(() => import('../pages/DevicePair/Screens/TabletPairSuccess'));
const PasswordUpdate = lazy(() => import('../pages/PasswordUpdate'));

type RouteProps = {
    exact?: boolean,
    path: string,
    [x: string]: any
}

export const PublicRoute: FunctionComponent<RouteProps> = ({component: Component, ...rest}) => {
    const authenticated = useSelector(isAuthenticated);
    return <Route {...rest} render={props => (!authenticated ? <Component {...props} /> : <Redirect to="/"/>)}/>;
};

export const PrivateRoute: FunctionComponent<RouteProps> = ({component: Component, ...rest}) => {
    const authenticated = useSelector(isAuthenticated);
    return <Route {...rest}
        render={props => (authenticated ? <Component {...props} /> : <Redirect to="/auth/login"/>)}/>;
};

export const TellerRoute: FunctionComponent<RouteProps> = ({component: Component, ...rest}) => <Route {...rest}
    render={props => (<Component {...props} />)}/>;

const authRoute = {
    path: '/auth',
    exact: false,
    component: Auth,
    route: PublicRoute,
};

const preloadRoute = {
    path: '/receipt/:id',
    exact: false,
    component: Preload,
    route: PublicRoute,
};

const digitizedReceiptRoute = {
    path: '/digitized_receipt/:id',
    exact: false,
    component: DigitizedReceipt,
    route: PublicRoute,
};

const devicePairRoute = {
    path: '/DevicePair/start',
    exact: false,
    component: DevicePair,
    route: PublicRoute,
};

const devicePairSuccessRoute = {
    path: '/DevicePair/success',
    exact: false,
    component: DeviceSuccess,
    route: PublicRoute,
};

const videoCallRoute = {
    path: '/video',
    exact: false,
    component: VideoCall,
    route: PublicRoute,
};

const devicePairIMEIRoute = {
    path: '/DevicePair/start',
    exact: false,
    component: DevicePairIMEI,
    route: PublicRoute,
};

const tabletPairIMEIRoute = {
    path: '/TabletPair/start',
    exact: false,
    component: TabletPairIMEI,
    route: PublicRoute,
};

const tabletPairSuccess = {
    path: '/TabletPair/success',
    exact: false,
    component: TabletPairSuccess,
    route: PublicRoute,
};

const tabletPairRoute = {
    path: '/TabletPair/start',
    exact: false,
    component: TabletPair,
    route: PublicRoute,
};

const successRoute = {
    path: '/tellerPortal/success',
    exact: true,
    component: Success,
    route: TellerRoute
};

const failureRoute = {
    path: '/tellerPortal/failure',
    exact: true,
    component: Failure,
    route: TellerRoute
};

const successUploadRoute = {
    path: '/tellerPortal/successUpload',
    exact: true,
    component: SuccessUpload,
    route: TellerRoute
};
const overviewRoute = {
    path: '/',
    exact: true,
    component: Overview,
    route: PrivateRoute,
};

const tellerRoute = {
    path: '/tellerPortal',
    exact: true,
    component: TellerPortal,
    route: PublicRoute,
};

const customerRoute = {
    path: '/customer',
    exact: true,
    component: Customer,
    route: PublicRoute
};

const forcePasswordRoute = {
    path: '/password_update',
    exact: true,
    component: PasswordUpdate,
    route: PublicRoute
};

const setupRoute = {
    path: '/setup',
    exact: true,
    component: Setup,
    route: PublicRoute
};

const receiptsRoute = {
    path: '/receipts',
    exact: true,
    component: Receipts,
    route: PrivateRoute,
};

const reportsRoute = {
    path: '/reports',
    exact: true,
    component: Reports,
    route: PrivateRoute,
};

const locationsRoute = {
    path: '/locations',
    exact: true,
    component: Locations,
    route: PrivateRoute,
};

const locationsIdRoute = {
    path: '/location',
    exact: true,
    component: LocationId,
    route: PrivateRoute,
};

const settingsRoute = {
    path: '/settings',
    exact: true,
    component: Settings,
    route: PrivateRoute,
};

const usersRoute = {
    path: '/users',
    exact: true,
    component: Users,
    route: PrivateRoute,
};

const auditRoute = {
    path: '/audit',
    exact: true,
    component: Audit,
    route: PrivateRoute,
};

const restrictedRoute = {
    path: '/restricted',
    exact: false,
    component: Components,
    route: PrivateRoute,
};

const error404Route = {
    path: '/*',
    exact: false,
    component: Error404,
    route: PublicRoute,
};

const getTellerFormRoutes = () => {
    const routes = pathsFlattened.map((path: string) => ({
        path: `/tellerPortal${path}`,
        exact: true,
        component: FormFlow,
        route: PublicRoute
    }));
    return routes;
};
const tellerProtalFormFlowPaths = getTellerFormRoutes();
// const appRoutes = [authRoute, overviewRoute, tellerRoute, ...tellerProtalFormFlowPaths, receiptsRoute, reportsRoute, error404Route];
const pairingRoutes = [devicePairIMEIRoute, devicePairSuccessRoute, tabletPairIMEIRoute, tabletPairSuccess];
const sigingRoutes = [preloadRoute, tellerRoute, successRoute, failureRoute, successUploadRoute, customerRoute, setupRoute, videoCallRoute];
const appRoutes = [forcePasswordRoute, ...pairingRoutes, ...sigingRoutes, ...tellerProtalFormFlowPaths, authRoute, overviewRoute, receiptsRoute, usersRoute, reportsRoute, locationsRoute, auditRoute, settingsRoute, digitizedReceiptRoute, restrictedRoute, error404Route];
const backOfficeNonAdminRoutes = [forcePasswordRoute, authRoute, overviewRoute, receiptsRoute, reportsRoute, settingsRoute, ...sigingRoutes, ...pairingRoutes, ...tellerProtalFormFlowPaths];

export {appRoutes, backOfficeNonAdminRoutes};
